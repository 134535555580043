import React from "react"
import { graphql, navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import Rating from "@material-ui/lab/Rating"
import ShopIcon from "@material-ui/icons/Shop"

import {
  FaPaintBrush,
  FaLaptopCode,
  FaLanguage,
  FaStarOfLife
} from "react-icons/fa"
import { MdChildCare, MdBusinessCenter } from "react-icons/md"
import { FcSportsMode } from "react-icons/fc"
import {
  GiFamilyHouse,
  GiOpenBook,
  GiCardRandom,
  GiMaterialsScience,
  GiMountaintop
} from "react-icons/gi"
import { IoIosBody, IoMdAirplane } from "react-icons/io"
import { BsPencil, BsFillPersonFill } from "react-icons/bs"
import { GrHistory } from "react-icons/gr"
import { FiCloud, FiHelpCircle } from "react-icons/fi"

import { usePersons } from "../hooks/use-persons"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

const bookCategoryIcons = {
  Adventure: <GiMountaintop size={20} />,
  Art: <FaPaintBrush size={20} />,
  "Biography & Autobiography": <BsFillPersonFill size={20} />,
  "Body, Mind & Spirit": <IoIosBody size={20} />,
  "Business & Economics": <MdBusinessCenter size={20} />,
  Children: <MdChildCare size={20} />,
  English: <FaLanguage size={20} />,
  "Family & Relationships": <GiFamilyHouse size={20} />,
  Fiction: <FiCloud size={20} />,
  History: <GrHistory size={20} />,
  Literature: <BsPencil size={20} />,
  "Non Fiction": <FaStarOfLife size={20} />,
  Novels: <GiOpenBook size={20} />,
  Others: <GiCardRandom size={20} />,
  Science: <GiMaterialsScience size={20} />,
  "Self Help": <FiHelpCircle size={20} />,
  "Sports & Recreation": <FcSportsMode size={20} />,
  Technology: <FaLaptopCode size={20} />,
  Travel: <IoMdAirplane size={20} />
}

export default function Book({ data }) {
  const book = data.postgres.book
  const persons = usePersons()

  const handlePersonClick = alias => {
    navigate("/persons/" + alias, { state: { alias: alias } })
  }

  const getPersonWithAlias = (persons, alias) => {
    return persons.filter(person => person.alias === alias)[0]
  }

  const recommendedByLength =
    book.recommendedBy == null ? 0 : book.recommendedBy.length
  const recommendedBySEOtext = recommendedByLength <= 1 ? "person" : "people"

  return (
    <>
      <SEO
        title={
          book.title +
          " recommended by " +
          recommendedByLength +
          " " +
          recommendedBySEOtext
        }
        description={
          book.title +
          " recommended by " +
          recommendedByLength +
          " " +
          recommendedBySEOtext
        }
      />
      <Header />
      <div
        style={{
          marginTop: "75px",
          paddingBottom: "25px",
          backgroundColor: "whitesmoke"
        }}
      >
        <Container style={{ paddingTop: 16 }}>
          <Grid container spacing={4}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              style={{
                marginBottom: "20px",
                marginTop: "10px",
                justifyContent: "center"
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={`https://www.amazon.com/gp/search?ie=UTF8&tag=nextbooktorea-20&linkCode=ur2&linkId=b7d713e2778f1bf22ba1ab463e9927ee&camp=1789&creative=9325&index=books&keywords=${
                  book.title
                } + ${book.authors.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "75%",
                    height: "auto"
                  }}
                  src={require("../images/books/" + book.alias + ".jpg")}
                  alt={book.title}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 16
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<ShopIcon />}
                    style={{
                      backgroundColor: "whitesmoke",
                      textTransform: "none",
                      fontSize: "large"
                    }}
                  >
                    Buy on Amazon
                  </Button>
                </div>
              </a>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              xl={9}
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                marginBottom: "20px",
                alignContent: "flex-start",
                flexDirection: "column"
              }}
            >
              <Typography variant="h2">{book.title}</Typography>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {book.categories.map(category => (
                  <Chip
                    style={{ margin: "4px 4px 4px 0px" }}
                    label={category}
                    variant={"default"}
                    color={"primary"}
                    icon={bookCategoryIcons[category]}
                  />
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <em>{"By"}&nbsp;</em>
                {book.authors.map(author => (
                  <b>{author}&nbsp;</b>
                ))}
                ({book.publishedDate})
              </div>
              <Rating
                name="half-rating-read"
                value={book.averageRating}
                precision={0.05}
                size="small"
                readOnly
              />
              <Typography variant="body2" color="textSecondary">
                {book.description}
              </Typography>
            </Grid>
          </Grid>
          {book.recommendedBy !== null && (
            <Typography variant="h5" style={{ marginBottom: "20px" }}>
              Recommended by {book.recommendedBy.length}{" "}
              {book.recommendedBy.length === 1 ? "person" : "people"}
            </Typography>
          )}
          <Grid container spacing={4}>
            {book.recommendedBy !== null &&
              book.recommendedBy.map(
                alias =>
                  persons.length !== 0 && (
                    <Grid
                      item
                      key={alias}
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      xl={2}
                      style={{ display: "flex" }}
                    >
                      <Card
                        style={{ borderRadius: 10, borderColor: "#496BA4" }}
                      >
                        <CardActionArea
                          onClick={() => handlePersonClick(alias)}
                        >
                          <CardMedia
                            component="img"
                            src={require("../images/persons/" + alias + ".jpg")}
                          />

                          <CardContent>
                            <Typography>
                              {getPersonWithAlias(persons, alias).name}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )
              )}
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($nodeId: ID!) {
    postgres {
      book(nodeId: $nodeId) {
        title
        authors
        categories
        description
        recommendedBy
        alias
        averageRating
        publishedDate
      }
    }
  }
`
